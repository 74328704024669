export default {
  heading: 'Great Vibes, serif',
  subheading: 'Raleway',
  body: 'Open Sans, sans-serif',
  monospace: 'Great Vibes, monospace',
  display: 'Great Vibes, serif',
  display2: 'Great Vibes, sans-serif',
  display3: 'Great Vibes',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Raleway:100,200,300,400,500,600,700,800,900',
    'Great Vibes',
    'Noto Serif Display:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: [],
  customUrls: []
}
