export default {
  text: '#000000',
  primary: '#e54c2a',
  secondary: '#009752',
  tertiary: '#009752',
  background: '#f7f7f7',
  backgroundSecondary: '#F7F7F7',
  light: '#FFF',
  dark: '#111111'
}
