// * This is Where the global Styles Exist
// *i.e will show up in the dom like  body p { color: red }

export default {
  root: {
    // *body styles go here at the root level
    margin: '0px',
    paddingBottom: ['', '', '3rem'],
    fontFamily: 'body',
    fontWeight: 'body',
    h1: {
      variant: 'text.heading',
      fontSize: '2rem'
    },
    h2: {
      variant: 'text.heading',
      fontSize: '1.75rem'
    },
    h3: {
      variant: 'text.heading',
      fontSize: '1.5rem'
    },
    h4: {
      variant: 'text.heading',
      fontSize: '1.4rem'
    },
    h5: {
      variant: 'text.heading',
      fontSize: '1.25rem'
    },
    h6: {
      variant: 'text.heading',
      fontSize: '1rem'
    },
    p: {
      variant: 'text.text'
    },
    a: {
      variant: 'text.link'
    },
    svg: {
      fill: 'inherit'
    },

    nav: {
      li: {
        a: {
          variant: 'text.navLink'
        }
      }
    },
    '.navItem a': {
      fontSize: ['1.2rem', '1.3rem', '1.3rem']
    }
  }
}
